import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * Represents the state for the Sign-Up popup visibility.
 * 
 * @typedef {Object} SignUpState
 * @property {boolean} value - Boolean value indicating if the Sign-Up popup is open or closed.
 */
interface SignUpState {
  value: boolean;
  reservationSidebar: boolean
  signUp: boolean
}

/**
 * The initial state for the Sign-Up popup visibility slice.
 * 
 * @type {SignUpState}
 */
const initialState: SignUpState = {
  value: false,
  reservationSidebar: false,
  signUp: false
};

/**
 * Slice to manage the state of the Sign-Up popup visibility.
 * 
 * @name signUpSlice
 */
const signUpSlice = createSlice({
  name: 'signin',
  initialState,
  reducers: {

    reservationSidbarOpenPopup: (state, action: PayloadAction<boolean>) => {
      state.reservationSidebar = action.payload;
    },

    reservationSidbarClosePopup: (state, action: PayloadAction<boolean>) => {
      state.reservationSidebar = action.payload;
    },


    openSignupPopup: (state, action: PayloadAction<boolean>) => {
      state.signUp = action.payload;
    },

    closeSignupPopup: (state, action: PayloadAction<boolean>) => {
      state.signUp = action.payload;
    },

    /**
     * Action to open the Sign-Up popup by setting `value` to true.
     * 
     * @function openPopup
     * @param {SignUpState} state - The current state of the Sign-Up popup.
     * @param {PayloadAction<boolean>} action - The action containing the boolean value to set the popup state.
     */
    openPopup: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    },

    /**
     * Action to close the Sign-Up popup by setting `value` to false.
     * 
     * @function closePopup
     * @param {SignUpState} state - The current state of the Sign-Up popup.
     * @param {PayloadAction<boolean>} action - The action containing the boolean value to set the popup state.
     */

    closePopup: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    }
  },
});

/**
 * Exporting the actions generated from the slice.
 * 
 * @type {function}
 */
export const { openSignupPopup, closeSignupPopup, openPopup, closePopup, reservationSidbarOpenPopup, reservationSidbarClosePopup } = signUpSlice.actions;

/**
 * Exporting the reducer to be used in the store.
 * 
 * @type {function}
 */
export default signUpSlice.reducer;
