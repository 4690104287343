import { reservationSidbarOpenPopup } from "@/store/slices/auth/signPopupSlice";
import { AppDispatch } from "@/store/store";
import { DeliveryAddress, GroupedAddresses, Tax } from "@/types/next";
import clsx, { ClassValue } from "clsx";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";

/**
 * Merge classes with tailwind-merge combined with the full features of clsx.
 *
 * @param {...ClassValue[]} inputs - Class values to be merged.
 * @returns {string} - The merged class string.
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Converts a timestamp into a human-readable date format.
 * 
 * @param {string | undefined} timestamp - The timestamp to be converted.
 * @returns {string} - Returns a human-readable date string such as 'Yesterday', 'Today 2:00 PM', or a day of the week.
 */
export const toHumanReadableDate = (timestamp: string | undefined): string => {
  if (timestamp === undefined) {
    return "ERROR";
  }

  const now = new Date();
  const date = new Date(timestamp);
  const oneMinute = 60 * 1000;
  const oneHour = 60 * oneMinute;
  const oneDay = 24 * oneHour;

  const diff = now.getTime() - date.getTime();

  if (date.toDateString() === now.toDateString()) {
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  } else if (diff < oneDay + (now.getTime() - now.setHours(0, 0, 0, 0))) {
    return "Yesterday";
  } else {
    return date.toLocaleDateString([], { weekday: "long" });
  }
};

/**
 * Returns the current time formatted as a human-readable date string.
 * 
 * @returns {string} - The current time as a human-readable string.
 */
export function getCurrentTime() {
  const currentDate = new Date();
  return toHumanReadableDate(currentDate.toISOString());
}

/**
 * Retrieves the current date in 'YYYY-MM-DD' format.
 * 
 * @returns {string} - The current date in 'YYYY-MM-DD' format.
 */
export const getCurrentDate = (): string => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

/**
 * Converts time from 24-hour format to 12-hour AM/PM format.
 * 
 * @param {string} time - The time in 24-hour format (e.g., '14:30').
 * @returns {string} - The time in 12-hour format (e.g., '2:30 PM').
 */
export function convertTo12HourFormat(time: string): string {
  const [hours, minutes] = time.split(":").map(Number);
  const period = hours >= 12 ? "PM" : "AM";
  const adjustedHour = hours % 12 || 12;
  return `${adjustedHour}:${minutes.toString().padStart(2, "0")} ${period}`;
}

/**
 * Converts time from 12-hour AM/PM format to 24-hour format.
 * 
 * @param {string} time - The time in 12-hour format (e.g., '2:30 PM').
 * @returns {string} - The time in 24-hour format (e.g., '14:30:00').
 */
export function convertTo24HourFormat(time: string) {
  let [hours, minutes] = time.split(":").map(Number);
  if (hours < 12) {
    hours += 12;
  }
  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:00`;
}

/**
 * Calculates the total tax amount based on a subtotal and tax rate.
 * 
 * @param {number} subtotal - The subtotal amount.
 * @param {number} [gstRate=2.5] - The GST rate in percentage (default is 2.5%).
 * @returns {number} - The calculated tax amount rounded to two decimal places.
 */
export function calculateTotalTaxes(subtotal: number, gstRate = 2.5) {
  if (typeof subtotal !== "number" || isNaN(subtotal) || subtotal < 0) {
    throw new Error("Invalid subtotal amount");
  }
  if (typeof gstRate !== "number" || isNaN(gstRate) || gstRate < 0) {
    throw new Error("Invalid rate");
  }
  const gstAmount = (subtotal * gstRate) / 100;
  return parseFloat(gstAmount.toFixed(2));
}

/**
 * Retrieves the tax percentage for a specific tax type from an array of tax objects.
 * 
 * @param {Tax[]} taxes - Array of tax objects.
 * @param {string} taxName - The name of the tax to search for.
 * @returns {number | null} - The tax percentage or null if not found.
 */
export function getTaxPercentage(taxes: Tax[], taxName: string) {
  if (!Array.isArray(taxes) || typeof taxName !== "string") {
    throw new Error("Invalid input");
  }
  const tax = taxes.find((t) => t.tax === taxName);
  return tax ? tax.tax_percentage : null;
}

/**
 * Determines the meal type (Lunch or Dinner) based on the time.
 * 
 * @param {string} time - The time in 12-hour format (e.g., '2:30 PM').
 * @returns {string} - Returns 'LUNCH' or 'DINNER'.
 */
export const determineMealType = (time: string) => {
  const hour = parseInt(time.split(":")[0], 10);
  const period = time.split(" ")[1];

  if (period === "PM") {
    if (hour >= 6) {
      return "DINNER";
    } else if (hour >= 1 && hour <= 3) {
      return "LUNCH";
    }
  } else if (period === "AM") {
    return "LUNCH";
  }
  return "LUNCH";
};

/**
 * Formats a number as a currency string.
 * 
 * @param {number} amount - The amount to be formatted.
 * @param {string} [locale="en-US"] - The locale (default is 'en-US').
 * @param {string} [currency="USD"] - The currency code (default is 'USD').
 * @returns {string} - The formatted currency string.
 */
export function formatCurrency(
  amount: number,
  locale: string = "en-US",
  currency: string = "USD"
): string {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  });
  return formatter.format(amount);
}

/**
 * Finds an item in an array by a specific key-value pair.
 * 
 * @template T - The type of the items in the array.
 * @param {T[]} data - The array of items.
 * @param {keyof T} key - The key to search by.
 * @param {any} value - The value to search for.
 * @returns {T | undefined} - The found item or undefined if not found.
 */
export function findItemByKeyValue<T extends { [key: string]: any }>(
  data: T[],
  key: keyof T,
  value: any
): T | undefined {
  return data.find((item) => item[key] === value);
}

/**
 * Groups delivery addresses based on their serviceability status.
 * 
 * @param {DeliveryAddress[]} addresses - The array of delivery addresses.
 * @returns {GroupedAddresses} - The grouped addresses categorized into serviceable and non-serviceable.
 */
export function groupAddressesByServiceability(addresses: DeliveryAddress[]): GroupedAddresses {
  const serviceableAddresses = addresses.filter(address => address.servicable);
  const nonServiceableAddresses = addresses.filter(address => !address.servicable);

  return {
    serviceableAddresses,
    nonServiceableAddresses
  };
}

export function findMatchingBranch(data: any[], nearByBranch: any): any | null {
  const { branch_id: nearBranchId, city_code: nearCityCode } = nearByBranch;
  // Find the city that matches the city_code
  const matchingCity = data?.find(city => city.city_code === nearCityCode);
  // If city is found, search for the branch within that city
  if (matchingCity) {

    const matchingBranch = matchingCity?.branches?.find((branch: any) => branch.branch_id === nearBranchId);

    // Only return the object if matchingBranch is found
    if (matchingBranch) {
      return {
        details: matchingBranch,
        branch_name: nearByBranch?.branch_name,
        city_name: nearByBranch?.city_name
      };
    }
  }
  // If no match is found, return null
  return null;
}




// Utility function to create a query string from searchParams
export function createQueryString(params: Record<string, any>, allowedKeys: string[]) {
  const query = new URLSearchParams();
  // Only include the allowed keys in the query string
  allowedKeys.forEach((key) => {
    if (params[key] !== undefined && params[key] !== null) {
      query.set(key, params[key]);
    }
  });

  return query.toString() ? `?${query.toString()}` : '';
}



export const isBot = (userAgent: string): boolean => {
  const botUserAgents = [
    "Google Page Speed Insights",
    'Googlebot',      // Google's bot
    'Lighthouse',     // PageSpeed Insights
    'Speed Insights', // General Speed Insight bots
    'Bingbot',        // Bing's bot
    'Baiduspider',    // Baidu bot
    'YandexBot',      // Yandex bot
    'DuckDuckBot',    // DuckDuckGo bot
    'SemrushBot',     // SEMrush SEO bot
    'MJ12bot',        // Majestic bot
    "Lighthouse", // PageSpeed Insights uses Lighthouse
    "Speed Insights",
    "Chrome-Lighthouse", // Different variations of user agents for Lighthouse
    "Googlebot", // For Googlebot if you want to exclude other bots
    // Add other user agents as necessary
  ];

  return botUserAgents.some((bot) => userAgent.includes(bot));
};


// Utility function to get a specific cookie by name
export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
  return null;
};

export const setCookie = (name: string, value: string, time: number) => {
  const date = new Date();
  date.setTime(date.getTime() + time * 60 * 60 * 1000); // Set expiration to 7 days from now
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value}; ${expires}; path=/`;
};




// promotions Buttons according to promotion_url
export const handlePromotionButtonClick = (promoUrl: string, selectedBranch: any, dispatch: AppDispatch) => {
  const lowerUrl = promoUrl.toLowerCase();

  if (lowerUrl === "catering") {
    window.location.href = "/catering";
  } else if (lowerUrl === "ubq" || lowerUrl === "delivery") {
    window.location.href = "/ubq-delivery";
  } else if (lowerUrl === "hpc") {
    window.location.href = "/vouchers";
  } else if (lowerUrl === "res") {
    if (!selectedBranch) {
      toast.warning("Please select a location!");
      return;
    }
    dispatch(reservationSidbarOpenPopup(true));
  }
};

export const getPromotionButtonText = (promoUrl: string) => {
  const lowerUrl = promoUrl.toLowerCase();

  if (lowerUrl === "ubq" || lowerUrl === "delivery") {
    return "Order Now";
  } else if (lowerUrl === "hpc") {
    return "Buy Now";
  } else if (lowerUrl === "catering") {
    return "Enquiry Now";
  }
  return "Reserve Table";
};


// generate valid body className according to slug
export function generateSlugClass(pathname: string): string {
  if (pathname === "/") return "home"; // special case for the homepage
  return pathname.replace(/\//g, "-").replace(/^-|-$/g, "");
}
